const theme = {
  colors: {
    text: "#131E26",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#38D430",
    secondary: "#ffffff",
    light: "#ffffff",
    dark: "#131E26",
    lightGrey: "#999999",
    darkBg:"#2A2727"
  },
  fonts: {
    body: 'bc-novatica-cyr, Arial, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      'bc-novatica-cyr, Arial, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 8, 16, 32, 48, 64, 96, 128, 192, 256, 320, 512],
  fontSizes: [12, 14, 16, 18, 20, 24, 32, 48, 64,72],
  fontWeights: {
    body: 400,
    heading: 500,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.042,
  },
  sizes: {
    container: 1280,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  divider:{
    my:[3],
    height:"4px",
    width:"100px",
    backgroundColor:"primary"
  },
  text: {
    default: {
      lineHeight: "body",
    },
    heading: {
      fontSize: [6],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      fontSize: [8, 9],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
    },
    h2: {
      fontSize: [6, 7],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "dark",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [6],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "dark",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 5,
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    p:{
      fontWeight:300,
      fontSize: [3], 
      "strong":{
        fontWeight:500
      }
    },
    kicker: {
      color: "primary",
      textTransform: "uppercase",
      position: "relative",
      display: "inline-block",
      fontSize: [0],
      "&:after": {
        content: "''",
        position: "absolute",
        width: "55px",
        backgroundColor: "primary",
        height: "1px",
        left: "130%",
        top: "50%",
        transform: "translateY(-50%)",
      },
    },
    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
  },
  layout: {
    container: {
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
    oneSideRight: {
      maxWidth: [
        "calc(((100% - 750px) / 2) + 750px) ",
        "calc(((100% - 970px) / 2) + 970px)",
        "calc(((100% - 1280px) / 2) + 1280px)",
      ],
      mr: ["auto", 0, 0, 0],
      padding: [3, 4],
      pr:[3,0,0,0]
    },
  },
  inputs:{
    primary: {
      p: [3],
      border: "1px solid",
      borderRadius: "0px!important",
      cursor: "pointer",
      color: "dark",
      borderColor: "dark",
      "&:focus": {
        outline: "none",
        backgroundColor: "dark",
        color: "light",
        border: "1px solid dark",
      },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "dark",
        opacity: 1 /* Firefox */,
      },
      "&::hover":{
        color:"primary"
      }
    },
  },
  buttons: {
    primaryForm:{
      cursor:"pointer",
      px:[4],
      py:[3],
      fontSize:[2],
      border:"1px solid",
      borderRadius:"30px",
      color:"primary",
      backgroundColor:"dark",
      "&:hover":{
        backgroundColor:"light"
      }
    },
    primary: {
      cursor:"pointer",
      px:[4],
      py:[3],
      fontSize:[2],
      border:"1px solid",
      borderRadius:"30px",
      color:"light",
      textDecoration:"none",
      backgroundColor:"primary",
      "&:hover":{
        backgroundColor:"light",
        color:"primary"
      }
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  links: {
    nav: {
      li: {
        position: "relative",
        marginX: 1,
        paddingX: 2,
        paddingY: 2,
        a: {
          textDecoration: "none",
          color: "light",
          "&:hover": {
            color: "primary",
            "&:before": {
              transformOrigin: "bottom left",
              transform: "scaleX(1)",
            },
          },
          "&:before": {
            content: "''",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "2px",
            backgroundColor: "primary",
            transition: "transform 0.4s ease-in-out",
            transform: "scaleX(0)",
            transformOrigin: "bottom right",
          },
        },
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      "*": {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        "text-rendering": "optimizeLegibility",
        "-webkit-transition": "background-color .2s linear,opacity .2s linea",
        "-ms-transition": "background-color .2s linear,opacity .2s linear",
        transition: "background-color .2s linear,opacity .2s linear",
      },
      fontFamily: "body",
      fontWeight: "body",
      a: {
        color: "primary",
        "&:hover": {
          textDecoration: "none",
        },
      },
      hr:{
        border:".5px solid",
        borderColor:"primary",
      },
      "--swiper-theme-color": "#00a889",
      ".swiper-container": {  height:"100%" },
      ".swiper-wrapper":{
        height:"100%!important"
      },
      ".swiper-slide" :{
        height: "100%!important"
      },
      ".swiper-pagination-bullet":{

          backgroundColor:"light"
      },
      ".swiper-pagination-bullet-active":{
        backgroundColor:"primary"
      }
    },
  },
}

export default theme
